/*
  STYLESHEET
  ------
  DESCRIPTION:
    Global structural styles
*/

.container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

main {
  flex-grow: 1;
  padding: 0 40px;
}

header,
footer {
  width: 100%;
  padding: 0 40px;
}