/*
  STYLESHEET
  ------
  DESCRIPTION:
    Used to define all project elements
*/

.project-list {
  display: flex;
  flex-flow: row wrap;
}

.project-card {
  padding: 20px;
}