/*
  STYLESHEET
  ------
  DESCRIPTION:
    Global footer styles
*/
@import '../utils/_utils';

footer {
  padding: 20px 40px;
}