/*
  STYLESHEET
  ------
  DESCRIPTION:
    Global header styles
*/
@import '../utils/_utils';

header {
  display: flex;
  flex-flow: wrap;
  align-items: center;
  justify-content: center;
  padding: 20px 40px;

  @include viewport(l) {
    flex-flow: nowrap;
    justify-content: space-between;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    display: inline;
    margin: 0;
    padding: 0 10px;
  }

  a {
    color: black;
    text-decoration: none;

    &.active {
      text-decoration: underline;
    }
  }
}

.logo-wrapper {
  display: block;
  width: 100%;

  @include viewport(l) {
    max-width: 200px;
  }
}

.logo {
  width: 100%;
  padding: 0 20px 20px;

  @include viewport(l) {
    padding: 0;
  }
}