/*
  STYLESHEET
  ------
  DESCRIPTION:
    Global body styles
*/

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  font-family: 'Quicksand', sans-serif;

  &.hidden {
    overflow: hidden;
  }
}

*,
*:after,
*:before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
}